var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-collapse",
    {
      model: {
        value: _vm.activeNameRecord,
        callback: function ($$v) {
          _vm.activeNameRecord = $$v
        },
        expression: "activeNameRecord",
      },
    },
    _vm._l(_vm.chargeRecordList, function (item, index) {
      return _c(
        "el-collapse-item",
        {
          key: index,
          class: _vm.chargeRecordList.length == 1 ? "hideHeader" : "",
          attrs: { title: "违规记录" + (index + 1), name: index },
        },
        [
          _c("div", { staticClass: "parkInfo" }, [
            _c("h2", { staticClass: "parkingTitle" }, [_vm._v("违规信息")]),
            _c("div", { staticClass: "parkInfoDetail" }, [
              _c("div", { staticClass: "infoItem" }, [
                _c("span", { staticClass: "infoLabel" }, [_vm._v("充电站")]),
                _c("span", [_vm._v(_vm._s(item.stationName))]),
              ]),
              _c("div", { staticClass: "infoItem" }, [
                _c("span", { staticClass: "infoLabel" }, [
                  _vm._v("停车泊位号"),
                ]),
                _c("span", [_vm._v(_vm._s(item.berthCode))]),
              ]),
              _c("div", { staticClass: "infoItem" }, [
                _c("span", { staticClass: "infoLabel" }, [_vm._v("充电桩")]),
                _c("span", [_vm._v(_vm._s(item.equipmentName))]),
              ]),
              _c("div", { staticClass: "infoItem" }, [
                _c("span", { staticClass: "infoLabel" }, [_vm._v("充电枪")]),
                _c("span", [_vm._v(_vm._s(item.connectorName))]),
              ]),
              _c("div", { staticClass: "infoItem red" }, [
                _c("span", { staticClass: "infoLabel" }, [_vm._v("违规类型")]),
                _c("span", [
                  _vm._v(
                    _vm._s(_vm._f("violationTypeName")(item.violationType))
                  ),
                ]),
              ]),
              _c("div", { staticClass: "infoItem" }, [
                _c("span", { staticClass: "infoLabel" }, [_vm._v("违规状态")]),
                _c("span", [
                  _vm._v(
                    _vm._s(_vm._f("violationStatusName")(item.violationStatus))
                  ),
                ]),
              ]),
              _c("div", { staticClass: "infoItem" }, [
                _c("span", { staticClass: "infoLabel" }, [
                  _vm._v("事件发生时间"),
                ]),
                _c("span", [_vm._v(_vm._s(item.happenTime))]),
              ]),
              _c("div", { staticClass: "infoItem" }, [
                _c("span", { staticClass: "infoLabel" }, [
                  _vm._v("记录创建时间"),
                ]),
                _c("span", [_vm._v(_vm._s(item.createdTime))]),
              ]),
              _c("div", { staticClass: "infoItem" }, [
                _c("span", { staticClass: "infoLabel" }, [
                  _vm._v("记录更新时间"),
                ]),
                _c("span", [_vm._v(_vm._s(item.updatedTime))]),
              ]),
              _c("div", { staticClass: "infoItem" }, [
                _c("span", { staticClass: "infoLabel" }, [
                  _vm._v("未归枪持续时长"),
                ]),
                _c("span", [
                  _vm._v(_vm._s(_vm._f("timeFormat")(item.unReturnGunTime))),
                ]),
              ]),
            ]),
            !item.images
              ? _c(
                  "div",
                  { staticClass: "imgList" },
                  [
                    _c("h2", [_vm._v("违规取证图片")]),
                    _vm._l(
                      "http://ace-image-1325768085.cos.ap-beijing.myqcloud.com/busy/20241024/HW012311231411040108/a27d0c92a32f00e58800e7c934e6aafb.jpg,http://ace-image-1325768085.cos.ap-beijing.myqcloud.com/busy/20241024/HW012311231411040108/a27d0c92a32f00e58800e7c934e6aafb.jpg".split(
                        ","
                      ),
                      function (value, idx) {
                        return _c("img", {
                          key: idx,
                          staticClass: "picItem",
                          attrs: { src: value },
                          on: {
                            click: function ($event) {
                              return _vm.showBigPic(value)
                            },
                          },
                        })
                      }
                    ),
                  ],
                  2
                )
              : _vm._e(),
          ]),
          item.chargeRecords.length
            ? _c(
                "div",
                { staticClass: "parkInfo" },
                [
                  _c("div", { staticClass: "parkingTitle" }, [
                    _vm._v("关联充电信息"),
                  ]),
                  _vm._l(item.chargeRecords, function (value, idx) {
                    return _c(
                      "div",
                      { key: idx, staticClass: "chargeRecordInfo" },
                      [
                        _c("div", { staticClass: "chargeRecordId" }, [
                          _vm._v("充电订单3232332323"),
                        ]),
                        _c("div", { staticClass: "parkInfoDetail" }, [
                          _c("div", { staticClass: "infoItem" }, [
                            _c("span", { staticClass: "infoLabel" }, [
                              _vm._v("电站名称"),
                            ]),
                            _c("span", [_vm._v(_vm._s(value.stationName))]),
                          ]),
                          _c("div", { staticClass: "infoItem" }, [
                            _c("span", { staticClass: "infoLabel" }, [
                              _vm._v("充电枪名称"),
                            ]),
                            _c("span", [_vm._v(_vm._s(value.connectorName))]),
                          ]),
                          _c("div", { staticClass: "infoItem" }, [
                            _c("span", { staticClass: "infoLabel" }, [
                              _vm._v("充电开始时间"),
                            ]),
                            _c("span", [_vm._v(_vm._s(value.startTime))]),
                          ]),
                          _c("div", { staticClass: "infoItem" }, [
                            _c("span", { staticClass: "infoLabel" }, [
                              _vm._v("充电结束时间"),
                            ]),
                            _c("span", [_vm._v(_vm._s(value.endTime))]),
                          ]),
                          _c("div", { staticClass: "infoItem" }, [
                            _c("span", { staticClass: "infoLabel" }, [
                              _vm._v("充电时长"),
                            ]),
                            _c("span", [
                              _vm._v(
                                _vm._s(_vm._f("timeFormat")(value.chargeLast)) +
                                  "分钟"
                              ),
                            ]),
                          ]),
                        ]),
                      ]
                    )
                  }),
                ],
                2
              )
            : _vm._e(),
        ]
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }